'use strict';

angular
   .module('schemaForm')
   .config(['schemaFormProvider', 'schemaFormDecoratorsProvider', 'sfPathProvider',
      function (schemaFormProvider, schemaFormDecoratorsProvider, sfPathProvider) {
         var defaultPatternMsg  = 'Wrong file type. Allowed types are ',
             defaultMaxSizeMsg1 = 'This file is too large. Maximum size allowed is ',
             defaultMaxSizeMsg2 = 'Current file size:',
             defaultMinItemsMsg = 'You have to upload at least one file',
             defaultMaxItemsMsg = 'You can\'t upload more than one file.';

         var nwpSinglefileUpload = function (name, schema, options) {
            if (schema.type === 'array' && schema.format === 'singlefile') {
               if (schema.pattern && schema.pattern.mimeType && !schema.pattern.validationMessage) {
                  schema.pattern.validationMessage = defaultPatternMsg;
               }
               if (schema.maxSize && schema.maxSize.maximum && !schema.maxSize.validationMessage) {
                  schema.maxSize.validationMessage  = defaultMaxSizeMsg1;
                  schema.maxSize.validationMessage2 = defaultMaxSizeMsg2;
               }
               if (schema.minItems && schema.minItems.minimum && !schema.minItems.validationMessage) {
                  schema.minItems.validationMessage = defaultMinItemsMsg;
               }
               if (schema.maxItems && schema.maxItems.maximum && !schema.maxItems.validationMessage) {
                  schema.maxItems.validationMessage = defaultMaxItemsMsg;
               }

               var f                                                  = schemaFormProvider.stdFormObj(name, schema, options);
               f.key                                                  = options.path;
               f.type                                                 = 'nwpFileUpload';
               options.lookup[sfPathProvider.stringify(options.path)] = f;
               return f;
            }
         };

         schemaFormProvider.defaults.array.unshift(nwpSinglefileUpload);

         var nwpMultifileUpload = function (name, schema, options) {
            if (schema.type === 'array' && schema.format === 'multifile') {
               if (schema.pattern && schema.pattern.mimeType && !schema.pattern.validationMessage) {
                  schema.pattern.validationMessage = defaultPatternMsg;
               }
               if (schema.maxSize && schema.maxSize.maximum && !schema.maxSize.validationMessage) {
                  schema.maxSize.validationMessage  = defaultMaxSizeMsg1;
                  schema.maxSize.validationMessage2 = defaultMaxSizeMsg2;
               }
               if (schema.minItems && schema.minItems.minimum && !schema.minItems.validationMessage) {
                  schema.minItems.validationMessage = defaultMinItemsMsg;
               }
               if (schema.maxItems && schema.maxItems.maximum && !schema.maxItems.validationMessage) {
                  schema.maxItems.validationMessage = defaultMaxItemsMsg;
               }

               var f                                                  = schemaFormProvider.stdFormObj(name, schema, options);
               f.key                                                  = options.path;
               f.type                                                 = 'nwpFileUpload';
               options.lookup[sfPathProvider.stringify(options.path)] = f;
               return f;
            }
         };

         schemaFormProvider.defaults.array.unshift(nwpMultifileUpload);

         schemaFormDecoratorsProvider.addMapping(
            'bootstrapDecorator',
            'nwpFileUpload',
            'directives/decorators/bootstrap/nwp-file/nwp-file.html'
         );
      }
   ]);

angular
   .module('ngSchemaFormFile', [
      'ngFileUpload',
      'ngMessages'
   ])
   .directive('ngSchemaFile', ['Upload', '$timeout', '$window', '$q', '$http', function (Upload, $timeout, $window, $q, $http) {
      return {
         restrict: 'A',
         scope:    true,
         require:  'ngModel',
         link: function (scope, element, attrs, ngModel) {
            scope.url = scope.form && scope.form.endpoint;
            scope.isSinglefileUpload = scope.form && scope.form.schema && scope.form.schema.format === 'singlefile';
			scope.endpoint = null;
			$window.console.info(scope.picFile);
			scope.lastState = "init";
			
			scope.deleteFile = function(url) {
				$http.delete(url).then(function (data) {
					$window.console.log("deleteFile status: " + data.status);
					scope.endpoint = null; 
                }).catch(function (data) {
					$window.console.log("deleteFile status: " + data.status);
					scope.endpoint = null; 
                });
				scope.lastState = "init";
			};
			
			// get and view default model 
			scope.defModel = eval("scope." + attrs.ngModel);
			$window.console.info(scope.defModel);
			if(scope.defModel) {
				scope.endpoint = scope.defModel.url;
				scope.filename = scope.defModel.filename;
				$window.console.log("name: " + scope.filename);
				$window.console.log("endpoint: " + scope.endpoint);
				scope.lastState = "default";
			}
			
			scope.newRemove = function(point) {
				scope.deleteFile(point);
			};
			
            scope.selectFile  = function (file) {
			   $window.console.log("selectFile");
               if(file != null) {
                  // write to getter value and replace not print chars
                  var filename = file.name.toString().split('.');
                  var newname = scope.form.title.toString();
                  newname = newname.replace(/\//g, " ");
                  newname = newname.replace(/\*/g, " ");
                  newname = newname.replace(/\\/g, " ");
                  newname = newname.replace(/-/g, " ");
				  newname = newname.replace(/,/g, " ");
				  newname = newname.replace(/:/g, " ");
                  filename[0] = (newname.length > 70) ? newname.substr(0, 70) : newname;
                  var name = ((filename[0]) ? filename[0] : "") + "." + ((filename.length > 0) ? filename[filename.length - 1] : "");
                  Object.defineProperty(file, 'name', {
                     value: name,
                     writable: true
                  });
                  $window.console.info(file);
               }
               scope.picFile = file;
			   scope.lastState = "select";
			   if(!scope.url) {
                   var reader = new FileReader();
                   reader.readAsDataURL(file);
                   reader.onload = function () {
                       ngModel.$setViewValue({name: file.name, mimeType: file.type, data: btoa(reader.result)});
                       ngModel.$commitViewValue();
                   };
                   reader.onerror = function (error) {
                       console.log('Error: ', error);
                   };
               }
                scope.$broadcast('schemaFormValidate');
            };

            scope.selectFiles = function (files) {
               scope.picFiles = files;
            };

            scope.uploadFile = function (file) {
               file && doUpload(file);
            };

            scope.removeFile = function (file) {
               $window.console.log("removeFile");
               $window.console.info(file);
               scope.picFile = null;
			   scope.lastState = "remove";
			   scope.$broadcast('schemaFormValidate');
            };

            scope.abortFile = function (file) {
//               $window.console.log("abortFile");
//               $window.console.info(file);
               if(file.upload != undefined) {
                  scope.picFile.upload.abort();
//                  $window.console.info(file);
               }
               else
               {
                  scope.picFile = null;
               }
            };

            scope.uploadFiles = function (files) {
               files.length && angular.forEach(files, function (file) {
                  doUpload(file);
               });
            };

            function doUpload(file) {
               if (file && !file.$error && scope.url) {
                  file.upload = Upload.upload({
                     url:  scope.url,
                     file: file
                  });

                  file.upload.then(function (response) {
                     $timeout(function () {
                        file.result = response.data;
                     });
                     ngModel.$setViewValue(response.data);
                     ngModel.$commitViewValue();
//					 $window.console.info(file);
					 scope.lastState = "upload";
					 scope.$broadcast('schemaFormValidate');
                  }, function (response) {
                     if (response.status > 0) {
                        scope.errorMsg = response.status + ': ' + response.data;
                     }
                  });

                  file.upload.progress(function (evt) {
                     file.progress = Math.min(100, parseInt(100.0 *
                        evt.loaded / evt.total));
                  });
               }
            }

            scope.validateField = function () {
				$window.console.log("validateField");
               if (scope.uploadForm.file && scope.uploadForm.file.$valid && scope.picFile && !scope.picFile.$error) {
                  console.log('singlefile-form is valid');
				  
				  if(scope.lastState == "select") {
					if (scope.form.required == true) ngModel.$setValidity('required', false);
				  }
				  else
				  {
					if (scope.form.required == true) ngModel.$setValidity('required', true);
				  }
				  
               } else if (scope.uploadForm.files && scope.uploadForm.files.$valid && scope.picFiles && !scope.picFiles.$error) {
                  console.log('multifile-form is  valid');
               } else {
                  console.log('single- and multifile-form are invalid');
				  ngModel.$setDirty();
				  
				  if(scope.picFile == null) {
				  	if(scope.lastState == "default") {
						if (scope.form.required == true) {
							ngModel.$setValidity('required', true);
							element.removeClass("ng-invalid-required");
							scope.uploadForm.$error.required = undefined;
						}
					}
					else
					{
						if (scope.form.required == true) ngModel.$setValidity('required', false);
					}
				  }
				  else
				  {
					if (scope.form.required == true) ngModel.$setValidity('required', true);
				  }
               }
            };
            scope.submit = function () {
               if (scope.uploadForm.file && scope.uploadForm.file.$valid && scope.picFile && !scope.picFile.$error) {
                  scope.uploadFile(scope.picFile);
               } else if (scope.uploadForm.files && scope.uploadForm.files.$valid && scope.picFiles && !scope.picFiles.$error) {
                  scope.uploadFiles(scope.picFiles);
               }
            };
            scope.$on('schemaFormValidate', scope.validateField);
            scope.$on('schemaFormFileUploadSubmit', scope.submit);
         }
      };
   }]);
